import React, { useState } from "react";
import Home from "./pages/Home";
import BucketListPage from "./pages/BucketListPage";
import BlogList from "./pages/BlogList"; // Importer la page BlogList
import Country from "./pages/Country";

const App = () => {
  const [currentPage, setCurrentPage] = useState("home");

  const renderPage = () => {
    switch (currentPage) {
      case "bucketlist":
        return <BucketListPage onNavigate={setCurrentPage} />;
      case "blog":
        return <BlogList onNavigate={setCurrentPage} />; // Ajouter BlogList ici
      case "country":
	return <Country onNavigate={setCurrentPage} />;
      default:
        return <Home onNavigate={setCurrentPage} />;
    }
  };

  return <div>{renderPage()}</div>;
};

export default App;

