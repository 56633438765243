import React from "react";
import BucketList from "../components/BucketList";
import "./BucketListPage.css";

const BucketListPage = ({ onNavigate }) => {
  return (
    <div className="bucketlist-page">
      <button className="back-button" onClick={() => onNavigate("home")}>
        ← Back
      </button>
      <div className="content">
        <h1 className="title">Slovakia Bucket List</h1>
        <BucketList />
      </div>
    </div>
  );
};

export default BucketListPage;

