import React, { useState } from "react";
import "./BucketList.css";

const items = [
  "🏰 Visit Budatín Castle",
  "🚶‍♂️ Walk around Mariánske Square",
  "🏞 Hike to Súľov Rocks (Súľovské skaly)",
  "⛪ Visit the Church of the Holy Trinity",
  "🚴 Bike along the Váh River",
  "🛶 Rafting on the Váh River",
  "🌲 Explore Mala Fatra National Park",
  "🚡 Take the cable car to Vrátna Valley",
  "🎿 Ski in Winter Park Martinky",
  "⛰ Climb Velký Rozsutec",
  "🌌 Explore the Jánošíkove Diery Gorge",
  "🏛 Visit the Rosenfeld Palace",
  "🏰 Day trip to Strečno Castle",
  "🚂 Take the historical forest railway in Orava",
  "🍲 Try traditional Žilina food",
  "🎭 Attend a performance at Žilina's National Theatre",
  "🌳 Relax at Rajecké Teplice Spa",
  "🏊‍♂️ Swim at Žilina Reservoir",
  "🧗‍♂️ Go rock climbing in Súľov",
  "🛍 Shop and dine at Aupark Mall",
];

const BucketList = () => {
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (index) => {
    setCheckedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    // Défilement fluide vers l'élément coché
    const element = document.getElementById(`item-${index}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="container">
      <h1 style={{ color: "white", textAlign: "center" }}>Slovakia Bucket List</h1>
      {items.map((item, index) => (
        <label key={index} id={`item-${index}`} htmlFor={`checkbox-${index}`}>
          <input
            type="checkbox"
            id={`checkbox-${index}`}
            checked={checkedItems[index] || false}
            onChange={() => handleCheckboxChange(index)}
          />
          {item}
        </label>
      ))}
    </div>
  );
};

export default BucketList;

