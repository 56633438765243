import React from "react";
import "./Country.css";

// Importation des images
import factImage from "../images/fact.gif";
import langageImage from "../images/langage.jpg";
import peopleImage from "../images/people.png";
import mannersImage from "../images/manners.jpg";
import economyImage from "../images/economy.png";
import foodImage from "../images/food.jpg";
import universityImage from "../images/university.png";

// Données des sections
const sections = [
  {
    id: 1,
    title: "Facts and Statistics",
    image: factImage,
    content: [
      "Location: Central Europe, south of Poland and sharing borders with Austria, Czech Republic, Hungary and Ukraine.",
      "Capital: Bratislava.",
      "Climate: temperate; generally warm summers; cold, cloudy, humid winters.",
      "Population: 5.4 million (2019 est.).",
      "Ethnic Make-up: Slovak 85.8%, Hungarian 9.7%, Roma 1.7%, Ruthenian/Ukrainian 1%, other and unspecified 1.8%.",
      "Religions: Roman Catholic 68.9%, Protestant 10.8%, Greek Catholic 4.1%, other or unspecified 3.2%, none 13%.",
      "Government: parliamentary democracy.",
    ],
  },
  {
    id: 2,
    title: "Language in Slovakia",
    image: langageImage,
    content: [
      'The Slovak language, sometimes referred to as "Slovakian", is an Indo-European language belonging to the West Slavic languages (together with Czech, Polish, Kashubian and Sorbian). Slovak is mutually intelligible with Czech.',
      "Slovak, as a written language, did not exist until the end of the 18th Century, when Anton Bernolak, a Roman Catholic priest set about to create a Slovak literary language.",
      'He based his creation on the Western Slovakian dialect and produced a phonetic spelling (one that is written as it is pronounced).',
      'Bernolak published his new language in his "Grammatica Slavica" in 1790. The language was further developed in 1843, when an agreement on the codification of Slovak as a written language was made. This was led by Ludovit Stur, who now chose the Central dialect as the basis, believing it to be the purest form.',
    ],
  },
  {
    id: 3,
    title: "Slovak People, Society and Culture",
    image: peopleImage,
    content: [
      "The people of Slovakia descend from the Slavic peoples who settled around the Danube river basin in the 6th and 7th centuries. Traditionally, Slovaks were what could be termed the “peasant class.” Their links to the earth and land still remain to this day.",
      "The political transformations of 1989 brought new freedoms that have considerably widened the societal outlook of the populace, yet many of the cultural movements are still in their infancy and consequently a large part of the elderly population is still rural and dependent on agriculture.",
      "On January 1, 1993 Slovakia became an independent nation-state, recognized by the United Nations and its member states.",
      "The family is the centre of the social structure. It, together with close friends, forms the basis of financial and emotional support.",
      "Slovakia has a large number of natural curative springs as well as extensive deposits of high-quality healing peat and mud.",
      "Slovaks value their privacy. It takes a while for them to open up to and trust new people.",
    ],
  },
  {
    id: 4,
    title: "Etiquette and Manners in Slovakia",
    image: mannersImage,
    content: [
      "Meeting and Greeting: Greetings are warm but not effusive. The most common greeting will be a handshake, direct eye contact and the relevant greeting for the time of day.",
      'Gift Giving Etiquette: If you are invited to a Slovak’s home, take wine, flowers or good quality chocolates for the hostess.',
      "Dining Etiquette: Remove your shoes at the door. Arrive on time - punctuality is appreciated. Dress well, i.e. like you are going to work, as this shows respect for your hosts.",
    ],
  },
  {
    id: 5,
    title: "Latest Economic Survey of the Slovak Republic (March 2024)",
    image: economyImage,
    content: [
      "The Slovak economy has been relatively resilient to the energy crisis, but growth has slowed amid high inflation, weakening foreign demand and tightening financial conditions.",
      "Improving housing affordability requires structural reforms to improve the efficiency of the housing market, property tax reforms, and targeted support to vulnerable households.",
    ],
  },
  {
    id: 6,
    title: "The food you must try when visiting Slovakia",
    image: foodImage,
    content: [
      "Bryndzové halušky, a classic Slovak meal featuring chewy potato dumplings and creamy cheese, topped with crispy bacon. It epitomizes the rustic charm of Slovak food.",
    ],
  },
  {
    id: 7,
    title: "University of Zilina",
    image: universityImage,
    content: [
      "University of Žilina (UNIZA) with its over sixty-five years of history belongs to one of the leading educational and scientific institutions in Slovakia.",
      "At present there are about 8 000 students being educated at seven faculties in 129 accredited study programmes in all forms and degrees of university studies.",
    ],
  },
];

const Country = () => {
  return (
    <div className="country-sections">
      {sections.map((section, index) => (
        <div key={section.id} className={`country-section ${index % 2 === 0 ? "image-right" : "image-left"}`}>
          <div className="country-content">
            <h2>{section.title}</h2>
            {section.content.map((line, idx) => (
              <p key={idx}>{line}</p>
            ))}
          </div>
          <img src={section.image} alt={section.title} className="country-image" />
        </div>
      ))}
    </div>
  );
};

export default Country;

