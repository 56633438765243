import React from "react";
import './BlogList.css';


// Données en dur (articles de blog)
const blogPosts = [
  {
    id: 1,
    title: "Premier article",
    media_url: "https://via.placeholder.com/150",
    content: "Ceci est le contenu de mon premier article.",
    date: "2024-12-15",
    author: "Auteur1",
  },
  {
    id: 2,
    title: "Deuxième article",
    media_url: "https://via.placeholder.com/150",
    content: "Ceci est le contenu de mon deuxième article.",
    date: "2024-12-16",
    author: "Auteur2",
  },
  {
    id: 3,
    title: "Troisième article",
    media_url: "https://via.placeholder.com/150",
    content: "Voici un troisième article de test avec un contenu un peu plus long.",
    date: "2024-12-17",
    author: "Auteur3",
  },
];

// Composant BlogCard (une carte par article)
const BlogCard = ({ title, media_url, content, date, author }) => {
  return (
    <div className="blog-card">
      <h3>{title}</h3>
      {media_url && <img src={media_url} alt={title} />}
      <p className="date">{date}</p>
      <p>{content}</p>
      <p className="author">Par : {author}</p>
    </div>
  );
};

// Composant principal BlogList
const BlogList = () => {
  return (
    <div className="blog-list">
      <h1>Liste des Articles</h1>
      <div className="blog-container">
        {blogPosts.map((post) => (
          <BlogCard
            key={post.id}
            title={post.title}
            media_url={post.media_url}
            content={post.content}
            date={post.date}
            author={post.author}
          />
        ))}
      </div>
    </div>
  );
};

export default BlogList;

