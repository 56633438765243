import React from "react";
import LinkComponent from "../components/LinkComponent";
import "./Home.css";

const Home = ({ onNavigate }) => {
  return (
    <div className="home">
      <div className="background-one">
        <LinkComponent
          className="link-one"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onNavigate("country");
          }}
        >
          Country
        </LinkComponent>
      </div>
      <div className="background-two">
        <LinkComponent
          className="link-two"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onNavigate("blog");
          }}
        >
          Blog
        </LinkComponent>
      </div>
      <div className="background-three">
        <LinkComponent
          className="link-three"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onNavigate("bucketlist");
          }}
        >
          Bucket List
        </LinkComponent>
      </div>
    </div>
  );
};

export default Home;

