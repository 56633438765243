import React from "react";
import "./LinkComponent.css";

const LinkComponent = ({ className, href, children, onClick }) => {
  return (
    <div className={`link-container ${className}`}>
      <a className={className} href={href} onClick={onClick}>
        {children}
      </a>
    </div>
  );
};

export default LinkComponent;

